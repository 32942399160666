import * as encode from 'nodejs-base64-encode';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import ProgramsModal from '../../../../components/templates/my-progress/ProgramsModal';
import ModalPause from './ModalPause';
import { Text } from '../../../../components/common/Texts';

// Material UI
import { Card } from '@mui/material';

// Utils
import { getInitials } from '../../../../utils';
import {
    capitalizeAllLetters,
    capitalizeFirstLetter,
} from '../../../../utils/stringUtils';

const ProgramCard = (props) => {
    const { program } = props;
    const history = useHistory();

    // STATE
    const [isOpen, setIsOpen] = useState(false);
    const [openModalPause, setOpenModalPause] = useState(false);
    const [report, setReport] = useState(false);
    // FUNCTIONS
    function handleOpenModal() {
        setIsOpen(true);
    }

    function handleCloseModal() {
        setIsOpen(false);
    }

    const handleRedirect = () => {
        if (program?.state === "cursado_suspendido") {
            setOpenModalPause(true);
            return;
        }
        const hash = encode.encode(String(program.id), 'base64');
        if (program.from === 'repo') {
            if (!!program.has_study_plan) {
                history.push(`../mi-progreso/${hash}`);
            }
        }
        if (program.from === 'sis') {
            setReport(true);
            handleOpenModal();
        }
    };

    return (
        <Wrapper>
            <ModalPause
                isOpen={openModalPause}
                handleCloseModal={() => setOpenModalPause(false)}
                programName={program.program}
                idProgram={program?.id}
            />
            <StateWrapper>
                <Text fontSize="16px" color="#222222" fontWeight="700">
                    Estado:
                </Text>
                <Chip
                    state={
                        program?.state ?? program?.academic_phase.toLowerCase()
                    }
                >
                    {capitalizeFirstLetter(
                        program?.state ?? program?.academic_phase
                    )}
                </Chip>
            </StateWrapper>
            <ProgramCardContainer
                from={program.from}
                studyPlan={program?.has_study_plan}
                title={program.program}
            >
                <Body>
                    <NameWrapper>
                        <Initials>{getInitials(program.program, 3)}</Initials>
                        <TitlesWrapper>
                            <Text
                                fontSize="14px"
                                color="#222222"
                                fontWeight="500"
                            >
                                {capitalizeAllLetters(program?.type_program)}
                            </Text>
                            <Plan>{program.program}</Plan>
                        </TitlesWrapper>
                    </NameWrapper>
                    <ProgressWrapper>
                        <LinearProgressWrapper>
                            <LinearProgress
                                porcentaje={
                                    program.total_progress > 100
                                        ? 100
                                        : program.total_progress
                                }
                            />
                        </LinearProgressWrapper>
                        <Percentage>
                            {program.total_progress > 100
                                ? 100
                                : Math.ceil(program.total_progress)}
                            %
                        </Percentage>
                    </ProgressWrapper>
                    <RedirectButton
                        disabled={
                            program?.state === 'baja'
                        }
                        onClick={handleRedirect}
                        variant="contained"
                    >
                        {program?.total_progress === 0
                            ? 'Iniciar'
                            : program?.total_progress === 100
                                ? 'Volver a ver'
                                : 'Continuar'}
                    </RedirectButton>
                </Body>
            </ProgramCardContainer>
            {report && (
                <ProgramsModal
                    isOpen={isOpen}
                    handleCloseModal={handleCloseModal}
                    program={program}
                />
            )}
        </Wrapper>
    );
};

export default ProgramCard;

const Wrapper = styled.div`
    position: relative;
`;

const ProgramCardContainer = styled(Card)`
    display: ${(p) =>
        p.from === 'sis' ? 'flex' : p.studyPlan ? 'flex' : 'none'};
    height: 101px;
    gap: 1rem;
    position: relative;
    border-radius: 20px;
    background-color: #ffffff;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    cursor: ${(p) =>
        p.from === 'sis' ? 'pointer' : p.studyPlan ? 'pointer' : 'default'};

    :hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        :before {
            opacity: 1;
        }
    }

    @media (max-width: 768px) {
        height: 212px;
        justify-content: space-between;
    }
`;

const StateWrapper = styled.div`
    position: absolute;
    /* top: -20px; */
    margin: auto;
    right: 0rem;
    top: -3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

const Chip = styled.div`
    width: fit-content;
    padding: 7px 16px;
    border-radius: 8px;

    background-color: ${(p) =>
        p.state === 'preinscripto'
            ? '#f9f5ec'
            : p.state === 'confirmado' || p.state === 'cursando'
                ? '#E6EBF9'
                : p.state === 'baja'
                    ? '#FCE9E8'
                    : p.state === 'egresado'
                        ? '#EAFAF6'
                        : p.state === 'cursado_suspendido'
                            ? '#F5D0C6'
                            : '#E9C0F0'};
    font-weight: 600;
    color: ${(p) =>
        p.state === 'preinscripto'
            ? '#C29F43'
            : p.state === 'confirmado' || p.state === 'cursando'
                ? '#5E80DB'
                : p.state === 'baja'
                    ? '#B31D15'
                    : p.state === 'egresado'
                        ? '#1E8065'
                        : p.state === 'cursado_suspendido'
                            ? '#DE4921'
                            : p.state === 'DEMO' && '#A43EB5'};
`;

const Body = styled.div`
    box-sizing: border-box;
    display: flex;
    background-color: white;
    position: relative;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    z-index: 3;
    width: 100%;
    border-top-left-radius: 20px;

    @media (width < 768px) {
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5rem;
        height: fit-content;
        height: 100%;
    }
`;

const NameWrapper = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    gap: 1.5rem;

    @media (width < 768px) {
        width: 100%;
    }
`;

const TitlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

const Plan = styled.h1`
    box-sizing: border-box;
    font-size: 20px;
    color: #222222;
    font-weight: 700;
    width: 100%;

    overflow: auto;

    ::-webkit-scrollbar {
        width: 1px;
    }
`;

const ProgressWrapper = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;

    @media (width < 768px) {
        width: 100%;
    }
`;

const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 7px;
    background-color: #eaeaea;
`;

const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 7px;
    background-color: #3bd39c;
`;

const Percentage = styled.span`
    font-size: 1.2rem;
    color: #222222;
    flex-wrap: wrap;
`;

const Initials = styled.div`
    width: 72px;
    height: 72px;
    min-width: 72px;
    border-radius: 50%;
    background-color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 0.3rem;
    color: #fff;

    @media screen and (max-width: 768px) {
        border-radius: 50%;
        font-size: 15px;
        min-width: 50px;
        max-width: 50px;
        height: 50px;
    }
`;

const RedirectButton = styled.button`
    height: 40px;
    width: 15%;
    border-radius: 10px;
    background-color: #cd2118;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 4rem;

    :disabled {
        cursor: not-allowed;
        background-color: #a9a9a9;
    }

    :not(:disabled) {
        :hover {
            background-color: #b31d15;
        }
    }

    @media (width < 768px) {
        width: 100%;
        margin-left: 0;
    }
`;
