import styled from "styled-components";

const CommonButton = (props) => {

    const { label, Icon, variant, onClick, disabled, width = "fit-content" } = props;

    return (
        <Button
            variant={variant}
            disabled={disabled}
            onClick={onClick}
            hasIcon={!!Icon}
            width={width}
        >
            {!!Icon && <Icon />}
            {label}
        </Button>
    )
}

export default CommonButton;

const Button = styled.button`
    cursor: pointer;
    width: ${({ width }) => width};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    transition: all 0.3s;
    border-radius: 100px;
    gap: ${({ hasIcon }) => hasIcon && "8px"};
    padding: ${({ hasIcon }) => hasIcon ? "12px 24px 12px 16px" : "12px 24px"};
    background-color: ${({ variant }) => variant === "filled" ? "#CD2118": "transparent"};
    color: ${({ variant }) => variant === "filled" ? "#FFF" : variant === "outlined" ? "#B31D15" : "#B31D15"};
    border: ${({ variant }) => variant === "outlined" ? "1px solid #B31D15" : "none"};

    &:hover{
        background-color: ${({ variant }) => variant === "filled" ? "#B31D15" : "#B31D15"};
        color: #FFF;
        box-shadow: 0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F;
    }

    &:disabled{
        cursor: not-allowed;
        box-shadow: none;
        background-color: ${({ variant }) => variant === "filled" ? "#DCDCDC" : "#FFF"};
        color: ${({ variant }) => variant === "filled" ? "#FFF" : "#DCDCDC"};
        border: ${({ variant }) => variant === "outlined" ? "1px solid #DCDCDC" : "none"};
    }
`