import React, { useState } from 'react';
import styled from 'styled-components';
import DownloadableModal from './DownloadableModal';
import DownloadButton from './DownloadButton';

const ebookIcon = process.env.PUBLIC_URL + '/assets/downloadable/ebook.svg';
const informeIcon = process.env.PUBLIC_URL + '/assets/downloadable/informe.svg';
const plantillaIcon =
    process.env.PUBLIC_URL + '/assets/downloadable/plantilla.svg';
const testIcon = process.env.PUBLIC_URL + '/assets/downloadable/test.svg';

const DownloadableCard = (props) => {
    const { value } = props;

    const [openModal, setOpenModal] = useState(false);
    const { subtype } = value;

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleType = (type) => {
        switch (type) {
            case 'PDF':
                return 'pdf';
            case 'xls':
                return 'xls';
            case 'img':
                return 'img';
            default:
                return 'doc';
        }
    };

    const handleChip = (type) => {
        switch (type) {
            case 'PDF':
                return 'E-book';
            case 'xls':
                return 'Plantilla';
            case 'img':
                return 'Test';
            default:
                return 'Informe';
        }
    };

    const handleIcon = (type) => {
        switch (type) {
            case 'PDF':
                return ebookIcon;
            case 'xls':
                return plantillaIcon;
            case 'img':
                return testIcon;
            default:
                return informeIcon;
        }
    };

    //solo descarga desde url de documento
    const name = value.publication_name;
    const link = value.link_material;
    const type = handleType(subtype);
    const id = value?.id;

    return (
        <>
            <Wrapper key={id}>
                <StyleImg src={handleIcon(subtype)}></StyleImg>
                <ChipStyle>{handleChip(subtype)}</ChipStyle>
                <TitleStyle>{name}</TitleStyle>
                <Description>{value?.description} </Description>
                <ButonMoreInfo onClick={() => setOpenModal(true)}>
                    Ver más info
                </ButonMoreInfo>
                <DownloadButton
                    link={link}
                    name={name}
                    fileType={type}
                    id={id}
                />
            </Wrapper>

            {/* ver mas info */}
            {openModal && (
                <DownloadableModal
                    openModal={openModal}
                    handleClose={handleClose}
                    link={link}
                    name={name}
                    fileType={type}
                    id={id}
                    description={value?.description}
                />
            )}
        </>
    );
};

export default DownloadableCard;

const Wrapper = styled.div`
    width: 250px;
    height: 400px;
    padding: 32px 24px 24px 24px;
    gap: 1rem;
    border-radius: 30px;
    opacity: 0px;
    background: #ffffff;
    border: 1px solid #a8a8a8;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyleImg = styled.img`
    height: 100px;
    object-fit: contain;
    object-position: center;
`;

const ChipStyle = styled.div`
    width: 50px;
    height: 20px;
    padding: 4px 16px 4px 16px;
    gap: 10px;
    border-radius: 100px;
    opacity: 0px;
    background: rgba(249, 249, 249, 1);
    border: 1px solid rgba(97, 97, 97, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: start;
`;

const TitleStyle = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    color: #b31d15;
`;

const Description = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #222222;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ButonMoreInfo = styled.div`
    color: #616161;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
`;
