import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

// Components
import HistorySubmissionStudent from './studentTask/HistorySubmissionStudent';
import StudentTaskHeader from './studentTask/StudentTaskHeader';
import CardCorrectTask from './studentTask/CardCorrectTask';
import CommentsSection from './studentTask/CommentsSection';
import InfoOldSection from './studentTask/InfoOldSection';
import { Text } from '../../../components/common/Texts';

// Material UI
import DownloadIcon from '@mui/icons-material/Download';
import Card from '@mui/material/Card';

// Redux
import { useSelector } from 'react-redux';
import {
    postAttachmentsFiles,
    setTaskByStudent,
    setTaskByStudentEdit,
} from '../../../redux/api/teacher';

// Utils
import { optionsStyle } from '../../../utils/snackStyles';
import { useSnackbar } from 'react-simple-snackbar';

// Hooks
import { useQueryParams } from "../../../hooks/useQueryParams";

const StudentTask = (props) => {
    const {
        idTask,
        idAssignment,
        state,
        valuesCorrection,
        isRedo,
        activeRol,
        history,
        createDate,
        correctionDate,
        studentComment,
        student,
        isFile,
        correctionType,
        order,
        teacherFiles,
        grade
    } = props;

    const { user } = useSelector((state) => state.auth);
    const [openSnackbar] = useSnackbar(optionsStyle);
    const historys = useHistory();
    const params = useQueryParams();
    const old = params.has("old");

    const correctionWithCheck = correctionType === 'check_entregado';

    // STATES
    const [note, setNote] = useState(0);
    const [comment, setComment] = useState('');
    const [redo, setRedo] = useState(false);
    const [check, setCheck] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [page, setPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);
    const [sound, setSound] = useState(null);
    const [video, setVideo] = useState(null);
    const [file, setFile] = useState(null);
    const [open, setOpen] = useState(false);

    const convertToLocalTime = (date, timezone) => {
        // Convierte la fecha y hora del evento a la zona horaria especificada
        const eventTime = moment.tz(date, timezone);

        // Convierte la hora del evento a la hora local
        const localTime = eventTime.clone().tz(moment.tz.guess());

        return localTime.format('DD/MM/YYYY HH:mm:ss');
    };

    const formatDate = convertToLocalTime(createDate, "UTC");
    const formatCorrectedDate = convertToLocalTime(correctionDate, "UTC");

    // USE EFFECTS
    // NO DEJAR QUE LA NOTA SE MODIFIQUE CON FLECHAS Y SCROLL
    useEffect(() => {
        const handleWheel = (event) => {
            if (document.activeElement.type === 'number') {
                document.activeElement.blur();
            }
        };

        const handleKeyDown = (event) => {
            if (
                document.activeElement.type === 'number' &&
                (event.key === 'ArrowUp' || event.key === 'ArrowDown')
            ) {
                event.preventDefault();
            }
        };

        document.addEventListener('wheel', handleWheel);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('wheel', handleWheel);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // FUNCTIONS
    const handleSendAudio = (audio) => {
        setSound(audio);
    };
    const handleSendVideo = (video) => {
        setVideo(video);
    };
    const handleSendFile = (fileParam) => {
        setFile(fileParam);
    };

    const handleNote = (e) => {
        const { value } = e.target;
        const currentNote = parseInt(value, 10);
        if (currentNote < 0) setNote(100);
        else if (currentNote > 100) setNote(0);
        else setNote(currentNote);
    };

    const handleComment = (e) => {
        const { value } = e.target;
        setComment(value);
    };

    const submitFiles = async (submissionId) => {
        try {
            const data = new FormData();
            if (file) {
                data.append('file_image', file);
            }
            if (video) {
                data.append('file_video', video);
            }
            if (sound) {
                data.append('file_audio', sound);
            }
            data.append('entrega_alumno', submissionId);

            await postAttachmentsFiles(data);

            openSnackbar('¡Corrección exitosa!');
            setPage(true);
            setLoading(false);
            setIsEdit(false);
            setEditSuccess(true);
        } catch (error) {
            openSnackbar(String(error));
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const body = {
                redo: redo,
                comment: comment,
                grade: correctionWithCheck ? 100 : note,
                professor_id: user.repo_id,
                submission_id: idTask,
                from_table: false,
            };
            const request = !isEdit
                ? await setTaskByStudent(body)
                : await setTaskByStudentEdit(body);

            if (request?.error) {
                throw new Error(request?.error);
            }

            submitFiles(request?.submission_id);
        } catch (error) {
            openSnackbar('¡No se pudo corregir el trabajo!');
        }
    };

    const handleRedo = (e) => {
        setRedo(e.target.checked);
    };

    const handleEdit = () => {
        setIsEdit(!isEdit);
    };

    const handleNavigate = () => {
        historys.push(`../../profesor/alumno/${student?.partner_id}`);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const correctedSubmission = !!page || state === 'corregido';

    return (
        <>
            <HistorySubmissionStudent
                history={history}
                open={open}
                handleClose={handleClose}
                idAssignment={idAssignment}
            />
            <TaskWrapper>
                <StudentTaskWrapper>
                    <StudentTaskHeader
                        correctedSubmission={correctedSubmission}
                        isRedo={isRedo}
                        redo={redo}
                        handleNavigate={handleNavigate}
                        old={old}
                        note={note}
                        history={history}
                        idAssignment={idAssignment}
                        grade={grade}
                        correctionWithCheck={correctionWithCheck}
                    />
                    <DownloadActivity>
                        <p>
                            Si no puedes visualizar la actividad
                            <span> puedes descargarla en tu ordenador.</span>
                        </p>
                        <CustomButtonA
                            target="_blank"
                            href={isFile.file}
                            download
                        >
                            <DownloadIcon />
                            Descargar actividad
                        </CustomButtonA>
                    </DownloadActivity>
                    <Body>
                        <Content>
                            <WrapperTitle>
                                <Text
                                    fontSize="16px"
                                    color="#222222"
                                    fontWeight="bold"
                                >
                                    Calificación
                                </Text>
                                <CustomButton onClick={handleOpen}>
                                    Ver historial de entregas
                                </CustomButton>
                            </WrapperTitle>
                            <CardCorrectTask
                                createDate={createDate}
                                formatDate={formatDate}
                                correctionDate={formatCorrectedDate}
                                studentComment={studentComment}
                                correctionWithCheck={correctionWithCheck}
                                check={check}
                                setCheck={setCheck}
                                correctedSubmission={correctedSubmission}
                                isEdit={isEdit}
                                editSuccess={editSuccess}
                                valuesCorrection={valuesCorrection}
                                note={note}
                                redo={redo}
                                handleNote={handleNote}
                                handleRedo={handleRedo}
                                old={old}
                                order={order}
                                
                            />
                        </Content>
                        <Content pd>
                            {
                                !old ?
                                    <CommentsSection
                                        correctedSubmission={correctedSubmission}
                                        isEdit={isEdit}
                                        editSuccess={editSuccess}
                                        valuesCorrection={valuesCorrection}
                                        comment={comment}
                                        activeRol={activeRol}
                                        handleComment={handleComment}
                                        handleSendAudio={handleSendAudio}
                                        handleSendVideo={handleSendVideo}
                                        handleSendFile={handleSendFile}
                                        loading={loading}
                                        check={check}
                                        handleEdit={handleEdit}
                                        redo={redo}
                                        correctionWithCheck={correctionWithCheck}
                                        note={note}
                                        handleSubmit={handleSubmit}
                                        teacherFiles={teacherFiles}
                                        idTask={idTask}
                                    />
                                    :
                                    <InfoOldSection
                                        valuesCorrection={valuesCorrection}
                                        studentComment={studentComment}
                                        teacherFiles={teacherFiles}
                                    />
                            }
                        </Content>
                    </Body>
                </StudentTaskWrapper>
            </TaskWrapper>
        </>
    );
}


export default StudentTask;

const TaskWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: auto;
    height: calc(100% - 61px);

    @media (max-width: 1200px) {
        border-radius: 20px;
    }
`;

const StudentTaskWrapper = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;

    @media (max-width: 1200px) {
        border-radius: 20px;
    }
`;

const Body = styled.div`
    padding: 0 1.5rem 1rem 1.5rem;
    width: calc(100% - 3rem);
    overflow-y: scroll;
    scrollbar-color: #e9e9e9 transparent;
    max-height: 90%;
`;




// Contenedor
const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: fit-content;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-top: ${(p) => p.pd && '1.5rem'};
`;

const CustomButton = styled.button`
    color: #616161;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    :hover {
        color: #b31d15;
    }
`;

const WrapperTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DownloadActivity = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem 1.5rem 1.5rem;
    box-sizing: border-box;
    border-bottom: solid 1px #c4c4c4;
    gap: 0.6rem;
    align-items: center;
    flex-direction: column;
    p {
        flex-basis: 50%;
        font-size: 0.95rem;
        text-align: center;
        span {
            font-weight: bold;
        }
    }
`;

const CustomButtonA = styled.a`
    border-radius: 8px;
    font-size: 0.9rem;
    background-color: #b31d15;
    padding: 0.5rem;
    text-align: center;
    display: flex;
    gap: 0.3rem;
    align-items: center;
    color: #fff;
    font-weight: bold;
    cursor: pointer;

    svg {
        font-size: 1.2rem;
    }
`;