import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Text from '../../../common/Text';
import { LinearProgress } from '@mui/material';
import { Icon } from '@iconify/react';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import Certification from '../my-progress/Certification';
import BackgroundPericles from '../../../../assets/media/aden/pericles-outlined-blanco.png';
import { getInitials } from '../../../../utils';

const ProgramInfoCard = (props) => {
    const { programData, programState, id, dataAssigment } = props;

    const screenWidth = useWindowWidth();

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setProgress(Math.ceil(programData?.progress?.progress));
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    const isFinish = (array) => {
        const result = array.filter(
            (assigment) => assigment?.progress < 100
        );
        return result.length === 0 ? true : false;
    };

    function formatDate(inputDate) {
        if (typeof inputDate !== 'string') {
            return null;
        }

        let dateParts = inputDate.split('-');

        let year = parseInt(dateParts[0]);
        let month = parseInt(dateParts[1]);
        let day = parseInt(dateParts[2]);

        let formattedDate =
            ('0' + day).slice(-2) + '/' + ('0' + month).slice(-2) + '/' + year;

        return formattedDate;
    }

    return (
        <Wrapper>
            {programState === 'DEMO' && (
                <DemoChip>
                    <Text
                        fontSize={screenWidth > 768 ? '16px' : '14px'}
                        fontWeight="700"
                        textColor="#FFFFFF"
                    >
                        DEMO
                    </Text>
                </DemoChip>
            )}
            <ProgramWrapper image={BackgroundPericles}>
                <IconContainer>
                    <Initials>{getInitials(programData?.name, 3)}</Initials>
                </IconContainer>
                <ProgramInfo>
                    <DatesWrapper>
                        <DateContainer>
                            <Icon
                                icon="fa6-regular:calendar-check"
                                width="1.2em"
                                height="1.2em"
                            />
                            <Date>
                                <Text
                                    fontSize="14px"
                                    fontWeight="700"
                                    textColor="#A8A8A8"
                                >
                                    INICIO
                                </Text>
                                <Text
                                    fontSize="14px"
                                    fontWeight="700"
                                    textColor="#616161"
                                >
                                    {formatDate(programData?.start_date) ||
                                        'xx/xx/xxxx'}
                                </Text>
                            </Date>
                        </DateContainer>
                        {programData?.graduation_date && (
                            <>
                                <OrnamentLineDate />
                                <DateContainer>
                                    <Icon
                                        icon="fa6-regular:calendar-xmark"
                                        width="1.2em"
                                        height="1.2em"
                                    />
                                    <Date>
                                        <Text
                                            fontSize="14px"
                                            fontWeight="700"
                                            textColor="#A8A8A8"
                                        >
                                            EGRESO
                                        </Text>
                                        <Text
                                            fontSize="14px"
                                            fontWeight="700"
                                            textColor="#616161"
                                        >
                                            {formatDate(
                                                programData?.graduation_date
                                            ) || 'xx/xx/xxxx'}
                                        </Text>
                                    </Date>
                                </DateContainer>
                            </>
                        )}
                    </DatesWrapper>
                    <TextWrapper>
                        <Text
                            fontSize={screenWidth > 768 ? '16px' : '14px'}
                            fontWeight="700"
                        >
                            PROGRAMA
                        </Text>
                        <Text
                            fontSize={screenWidth > 768 ? '24px' : '20px'}
                            fontWeight="700"
                            ellipsis={screenWidth > 768}
                            textColor="#B31D15"
                        >
                            {programData?.name}
                        </Text>
                    </TextWrapper>
                    <ProgressWrapper>
                        {/* <IconWrapper>
            <Icon icon="clarity:user-solid" />
          </IconWrapper> */}
                        <BarWrapper>
                            <ProgramLinearProgress
                                variant="determinate"
                                value={progress}
                            />
                        </BarWrapper>
                        {/* <IconWrapper>
            <Icon icon="fa6-solid:user-graduate" />
          </IconWrapper> */}
                    </ProgressWrapper>
                    <ProgramDetails>
                        <DetailsWrapper>
                            <DetailItem>
                                <Icon icon="ion:book" />
                                <Text fontSize="16px" fontWeight="400">
                                    <b>{programData?.progress?.total}</b>{' '}
                                    asignaturas
                                </Text>
                            </DetailItem>
                            <OrnamentLine />
                            <DetailItem>
                                <Icon icon="mingcute:time-fill" />
                                <Text
                                    fontSize={
                                        screenWidth > 768 ? '16px' : '14px'
                                    }
                                    fontWeight="400"
                                >
                                    {programData?.duration && (
                                        <>
                                            <b>
                                                {programData?.duration?.split(
                                                    ' '
                                                )[0] ?? 'Sin definir'}
                                            </b>{' '}
                                            {programData?.duration?.split(
                                                ' '
                                            )[1] ?? ''}
                                        </>
                                    )}
                                </Text>
                            </DetailItem>
                            {programData?.has_certificate && (
                                <>
                                    <OrnamentLine />
                                    <Certification
                                        id={id}
                                        avaliable={isFinish(dataAssigment)}
                                        type="PROGRAM"
                                    />
                                </>
                            )}
                        </DetailsWrapper>
                    </ProgramDetails>
                </ProgramInfo>
            </ProgramWrapper>
        </Wrapper>
    );
};

export default ProgramInfoCard;

const Wrapper = styled.div`
    position: relative;
    height: 280px;
    width: 100%;
    display: flex;
    border-radius: 30px;
    box-shadow: 0px 8px 8px -4px rgba(24, 39, 75, 0.08),
        0px 4px 6px -4px rgba(24, 39, 75, 0.12);
    z-index: 8;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 3fr;
    }

    @media screen and (max-width: 768px) {
        height: 100%;
        margin-top: 3rem;
        display: flex;
    }
`;

const IconContainer = styled.div``;

const Initials = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 100px;
    background-color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
`;

const ProgramWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    min-width: 100%;
    width: 100%;
    height: 280px;
    border-radius: 30px;
    padding: 3rem;
    gap: 2rem;
    z-index: 5;
    background-color: #f9f9f9;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: right;
    background-size: 25% 100%;
    box-shadow: 0px 8px 8px -4px rgba(24, 39, 75, 0.08),
        0px 4px 6px -4px rgba(24, 39, 75, 0.12);

    @media screen and (max-width: 768px) {
        padding: 3rem 2rem;
        background-image: none;
        height: fit-content;
    }
`;

const ProgramInfo = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2rem;
`;

const DatesWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    top: 2rem;
    right: 2rem;

    @media (width < 768px) {
        display: none;
    }
`;

const DateContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

const OrnamentLineDate = styled.span`
    height: 35px;
    width: 2px;
    background-color: #b31d15;
`;

const Date = styled.div`
    display: flex;
    flex-direction: column;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    @media screen and (max-width: 768px) {
    }
`;

const ProgressWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.8rem;

    @media screen and (max-width: 768px) {
        width: 165%;
        margin-left: -65%;
    }
`;

const BarWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #eaeaea;
    border-radius: 20px;
    padding: 3px;
`;

const ProgramLinearProgress = styled(LinearProgress)`
    box-sizing: border-box !important;
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 20px;
    overflow: visible !important;
    background-color: transparent;

    /* el progreso de la barra se controla por width y se deshabilita transform-translate */
    .MuiLinearProgress-bar {
        border-radius: 20px !important;
        width: ${(p) => p.value}%;
        background-color: #35d0a5 !important;
        transition: ease-out 1s !important;
    }

    .MuiLinearProgress-bar1Determinate {
        transform: none !important;
        transition: all 1s !important;
        z-index: 20 !important;
    }

    .MuiLinearProgress-bar1Determinate::before {
        content: '${(p) => p.value}%';
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: -1.7rem;
        width: 100%;
        color: #222222;
        font-weight: 700;
        font-size: 16px;
    }

    @media screen and (max-width: 768px) {
        height: 20px;
        .MuiLinearProgress-bar {
            width: ${(p) => p.value}%;
        }
    }

    @media screen and (max-width: 425px) {
        width: 100%;
        .MuiLinearProgress-bar {
            width: ${(p) => p.value}%;
        }
    }
`;

const ProgramDetails = styled.div`
    display: flex;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const DetailsWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
`;

const DetailItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    svg {
        width: 25px;
        height: 25px;
        color: #b31d15;
    }
`;

const OrnamentLine = styled.span`
    height: 35px;
    width: 2px;
    margin: 0 2rem;
    background-color: #b31d15;
`;

const DemoChip = styled.span`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    right: 2rem;
    top: 5rem;
    z-index: 6;
    border-radius: 10px;
    background: linear-gradient(#b31d15, #841510);

    @media screen and (max-width: 1024px) {
        width: 80px;
        height: 30px;
        right: 1.2rem;
        top: 2rem;
    }

    @media screen and (max-width: 768px) {
        width: 80px;
        height: 30px;
        right: 1.2rem;
        top: 2rem;
    }
`;
