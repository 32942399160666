import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Components
import ModalProblem from '../../../../../components/common/modals/ModalProblem';
import Text from '../../../../../components/common/Text';
import { Icon } from '@iconify/react';

// Context
import CourseSubjectContext from '../../../context/CourseSubjectContext';
import CircularProgress from './subjectInfoCard/CircularProgress';
import StateChip from './subjectInfoCard/StateChip';
import BottomContent from './subjectInfoCard/BottomContent';
import SubjectDates from './subjectInfoCard/SubjectDates';

const SubjectInfoCard = () => {

    const {
        data: {
            name,
            duration,
            courses,
            progress,
            date,
            expirationDate,
            enrollmentState,
            enrollmentFinalNote,
            reworkActivities
        }
    } = useContext(CourseSubjectContext);

    let expiringDate = new Date(expirationDate + 'T00:00:00');

    expiringDate.setHours(23);
    expiringDate.setMinutes(59);
    expiringDate.setSeconds(59);

    const isExpired = expiringDate <= new Date(Date.now());

    const history = useHistory();

    const [openModalExpired, setOpenModalExpired] = useState(false);
    const [openModalBloqued, setOpenModalBloqued] = useState(false);

    const handleClickModal = () => {
        history.push('/support');
    };

    return (
        <>
            <ModalProblem
                isOpen={openModalExpired}
                title={'Asignatura vencida'}
                description={
                    'Lo sentimos, ya expiró el plazo para cursar esta asignatura. Para poder continuar con tu cursado, deberás comunicarte con tu '
                }
                handleCloseModal={() => setOpenModalExpired(false)}
                handleClick={handleClickModal}
            />
            <ModalProblem
                isOpen={openModalBloqued}
                title={'Asignatura bloqueada'}
                description={
                    'Lo sentimos, para poder continuar con tu cursado, deberás comunicarte con tu '
                }
                handleCloseModal={() => setOpenModalBloqued(false)}
                handleClick={handleClickModal}
            />
            <CardWrapper>
                <ProgressWrapper>
                    <CircularProgress
                        progress={Math.trunc(progress?.progress)}
                    />
                </ProgressWrapper>
                <AssignmentInfo>
                    <LeftInfo>
                        <>
                            <SubjectInfo>
                                <Text
                                    fontSize='16px'
                                    fontWeight="700"
                                >
                                    ASIGNATURA
                                </Text>
                                <StateChip
                                    enrollmentState={enrollmentState}
                                    isExpired={isExpired}
                                    setOpenModalExpired={setOpenModalExpired}
                                    setOpenModalBloqued={setOpenModalBloqued}
                                />
                            </SubjectInfo>
                            {
                                enrollmentState === "egresado" && (
                                    <Grade>
                                        <Icon icon="dashicons:awards" width="24px" height="24px" />
                                        <Text
                                            fontSize='16px'
                                            fontWeight="700"
                                        >
                                            Nota: <b>{enrollmentFinalNote}</b> / 100
                                        </Text>
                                    </Grade>
                                )
                            }
                            <Text
                                fontSize='24px'
                                fontWeight="700"
                                color="#B31D15"
                                style={{ maxWidth: '400px' }}
                            >
                                {name}
                            </Text>
                        </>
                        <BottomContent
                            coursesLength={courses?.length}
                            duration={duration}
                        />
                    </LeftInfo>
                    <RightInfo>
                        <DateInfo>
                            {reworkActivities && (
                                <ReworkActivitiesChip>
                                    <Icon
                                        icon="mage:exclamation-circle"
                                        width="24px"
                                        height="24px"
                                    />
                                    Tienes actividades por rehacer
                                </ReworkActivitiesChip>
                            )}
                            <SubjectDates 
                                date={date}
                            />
                        </DateInfo>
                    </RightInfo>
                </AssignmentInfo>
            </CardWrapper>
        </>
    );
};

export default SubjectInfoCard;

const CardWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 48px;
    max-width: 100%;
    min-height: 221px;
    padding: 2rem 40px 2rem 68px;
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    z-index: 5;
    border: 1px solid #A8A8A8;

    @media (width < 1140px) {
        padding: 2rem 1rem;
    }

    @media (width < 1068px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 2rem;
        gap: 3rem;
        height: 100%;
    }
`;

const ProgressWrapper = styled.div`
    position: relative;
    width: fit-content;
    height: 100%;

    @media (max-width: 768px) {
        width: 190px;
        height: 190px;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const AssignmentInfo = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    min-height: 221px;

    @media (width < 1068px) {
        flex-direction: column;
        align-items: start;
    }
`;

const LeftInfo = styled.div`
    min-height: 240px;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;

    @media (width < 1068px) {
        justify-content: space-between;
        min-height: fit-content;
        gap: 24px;
    }
`;

const SubjectInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const Grade = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    svg {
        color: #b31d15;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        color: #222;
        white-space: nowrap;

        b {
            font-weight: 900;
        }
    }
`;

const RightInfo = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
    padding: 1rem 0;
    z-index: 5;

    @media (width < 1068px) {
        width: 100%;
        align-items: start;
        border-top: 1px solid #A8A8A8;
        padding-top: 2rem;
    }
`;

const DateInfo = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 3rem;
`;

const ReworkActivitiesChip = styled.div`
    font-size: 13px;
    font-weight: 700;
    padding: 8px 16px 8px 8px;
    border-radius: 100px;
    background-color: #fff;
    color: #b31d15;
    border: 1px solid #b31d15;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;
    z-index: 10;

    svg {
        color: #b31d15;
    }
`;
